import * as React from 'react';
import { Col, Row, Table } from 'reactstrap';
import _ from 'lodash';
import saveData from '../data/save.json';
import { MainHeading, Markdown, SubPageLayout, SubSection, SubSections } from '../components';

export const SavePageInternal = (save: typeof saveData) => {
  return (
    <SubPageLayout
      title="Services"
      headerImageAnchor="left"
      imageSrc={save.headerImageSrc}
    >
      <MainHeading
        label="Save"
        text={save.description}
      />

      <SubSections>
        {[
          <SubSection key="checking" anchor="checking" headerLabel="Checking">
            <Row className="mb-3">
              <Col className="text-center child-spacing-x-5">
                <i className="fas fa-money-check section-icon" />
              </Col>
            </Row>

            <Row className="center-headings">
              <Col md={6}>
                <Markdown source={save.checking} />
              </Col>
              <Col md={6}>
                <Markdown source={save.businessChecking} />
              </Col>
            </Row>
          </SubSection>,
          <SubSection key="savings" anchor="savings" headerLabel="Savings">
            <Row className="mb-3">
              <Col className="text-center child-spacing-x-5">
                <i className="fas fa-piggy-bank section-icon" />
              </Col>
            </Row>
            <Row className="center-headings">
              <Col md={6}>
                <Markdown source={save.regularSavings} />
              </Col>
              <Col md={6}>
                <Markdown source={save.specialSavings} />
              </Col>
            </Row>
          </SubSection>,
          <SubSection key="certificates" anchor="certificates" headerLabel="Certificates (CDs)">
            <Row className="mb-3">
              <Col className="text-center child-spacing-x-5">
                <i className="fas fa-money-bill-wave section-icon" />
              </Col>
            </Row>
            <Row>
              <Col className="d-flex flex-column" md={12}>
                <Markdown source={save.certificates} />
              </Col>
            </Row>
          </SubSection>,
          <SubSection key="retirement-accounts" anchor="retirement-accounts" headerLabel="Retirement Accounts (IRAs)">
            <Row className="mb-3">
              <Col className="text-center child-spacing-x-5">
                <i className="fas fa-chart-line section-icon" />
              </Col>
            </Row>
            <Row>
              <Row className="center-headings">
                <Col md={6}>
                  <Markdown source={save.traditionalRetirementAccounts} />
                </Col>
                <Col md={6}>
                  <Markdown source={save.rothRetirementAccounts} />
                </Col>
              </Row>
            </Row>
          </SubSection>,
          <SubSection key="health-savings-account" anchor="health-savings-account" headerLabel="Health Savings Account (HSA)">
            <Row className="mb-3">
              <Col className="text-center child-spacing-x-5">
                <i className="fas fa-file-invoice-dollar section-icon" />
              </Col>
            </Row>
            <Row>
              <Col className="d-flex flex-column" md={12}>
                <Markdown source={save.healthSavingsAccounts} />
              </Col>
            </Row>
          </SubSection>,
          <SubSection key="savings-rates" anchor="savings-rates" headerLabel="Savings Rates">
            <Table borderless size="sm">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>APR*</th>
                  <th>APY**</th>
                </tr>
              </thead>
              <tbody>
                {
                  _.map(save.savingsRates, (rate, i) => (
                    <tr key={i}>
                      <td>{rate.type}</td>
                      <td>{rate.apr}</td>
                      <td>{rate.apy}</td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>

            <Markdown source={save.savingsRatesAside} />
          </SubSection>,
        ]}
      </SubSections>
    </SubPageLayout>
  );
};

export default () => <SavePageInternal {...saveData} />;
